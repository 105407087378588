import React, { useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import Cleave from "cleave.js/react";

import { setTenantNote, setInsuranceId, fullDayAccessPath } from "../../../Utils/Common";
import BackButton from "../../../shared/BackButton";
import { setInput, resetKeyboard } from "../../../../redux/Keyboard/keyboard.action";

function TenantNotes(props) {
  const dispatch = useDispatch();

  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  useEffect(() => {
    dispatch(resetKeyboard(["carrier", "policy_number", "expiration_date", "submit"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setFieldValue(currentInput, currentValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!props.isValid) {
      props.setTouched({ carrier: true, policy_number: true, expiration_date: true });
      return;
    }

    const note = `Carrier ${props.values.carrier}, Policy Number ${props.values.policy_number}, Expiration Date ${props.values.expiration_date}`;

    setInsuranceId("0");
    setTenantNote(note);
    dispatch(resetKeyboard([]));
    props.history.push(fullDayAccessPath(), { prevPageUrl: "/tenant-notes" });
  };

  const handleFocus = (inputName, e) => {
    dispatch(setInput(inputName, e.target.value));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="font-weight-bold text-center mb-4">Enter Insurance details</h1>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      placeholder="Carrier"
                      type="text"
                      name="carrier"
                      className={`form-control form-control-lg ${currentInput === "carrier" ? "focus" : ""}`}
                      value={props.values.carrier}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      onFocus={(e) => handleFocus("carrier", e)}
                    />
                    <span className="text-danger">{props.touched.carrier && props.errors.carrier}</span>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Policy Number"
                      type="text"
                      name="policy_number"
                      className={`form-control form-control-lg ${currentInput === "policy_number" ? "focus" : ""}`}
                      value={props.values.policy_number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={(e) => handleFocus("policy_number", e)}
                    />
                    <span className="text-danger">{props.touched.policy_number && props.errors.policy_number}</span>
                  </div>
                  <div className="form-group">
                    <Cleave
                      placeholder="Expiration Date eg: mm/dd/yyyy"
                      className="form-control"
                      name="expiration_date"
                      options={{ date: true, datePattern: ["m", "d", "Y"] }}
                      value={props.values.expiration_date}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={(e) => handleFocus("expiration_date", e)}
                    />
                    <span className="text-danger">{props.touched.expiration_date && props.errors.expiration_date}</span>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 text-right">
                  <button
                    name="submit"
                    type="submit"
                    disabled={props.isSubmitting}
                    className="btn btn-pink btn-lg letter-spacing-2"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <BackButton backPath="/insurance" />
    </div>
  );
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      carrier: "",
      policy_number: "",
      expiration_date: "",
    };
  },
  validationSchema: Yup.object().shape({
    carrier: Yup.string().required("Carrier required"),
    policy_number: Yup.string().required("Policy Number required"),
    expiration_date: Yup.string().required("Expiration Date Date required"),
  }),
})(TenantNotes);
