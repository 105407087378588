import React, { Component } from "react";
import { BulletList } from "react-content-loader";
import axios from "axios";
import XMLParser from "react-xml-parser";
import {
  locationCode,
  reservationObject,
  unitObject,
  setInsuranceId,
  setInsuranceCoverage,
  purgeTenantNote,
  setInsuranceStatus,
  getInsuranceType,
  locationObject,
  fullDayAccessPath,
} from "../../../Utils/Common";
import BackButton from "../../../shared/BackButton";
class Insurance extends Component {
  constructor(props) {
    super(props);
    if (!reservationObject()) {
      this.props.history.push("/home");
    }
    this.state = {
      insurances: [],
      selectedValue: 0,
      unit: unitObject(),
      date: new Date(),
    };
  }

  componentDidMount() {
    const data = {
      location_code: locationCode(),
    };
    axios
      .post("/api/ssm/GetInsuranceSchemes", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        let data = new XMLParser().parseFromString(response.data);
        this.setState({
          insurances: data.getElementsByTagName("InsuranceSchemes")[0].children || "",
        });
      });

    purgeTenantNote();
  }

  selectInsurance = (insurance) => {
    const insuranceId = insurance.children[0].value;
    const coverage = insurance.children[2].value;

    this.setState({ selectedValue: insuranceId });

    setInsuranceId(insuranceId);
    setInsuranceCoverage(coverage);
  };

  handleNext = () => {
    setInsuranceStatus("1");
    const { isSBOAEnabled, pdfUrl } = this.isSBOAEnabled();
    if (isSBOAEnabled) {
      this.props.history.push("/shield-brochure");
      sessionStorage.setItem("shieldBrochurePdfUrl", pdfUrl);
      return;
    }
    this.props.history.push(fullDayAccessPath(), { prevPageUrl: "/insurance" });
  };

  isSBOAEnabled = () => {
    const locationObjectData = locationObject() || {};
    return { isSBOAEnabled: locationObjectData?.sboa, pdfUrl: locationObjectData.sboa_brochure };
  };

  handleHoi = () => {
    setInsuranceId("0");
    setInsuranceStatus("2");
    this.props.history.push("/tenant-notes");
  };

  render() {
    const insuranceList = () => {
      return this.state.insurances.map((value, index) => (
        <div className="col-sm-12 mb-3 pointer" key={index}>
          <div
            className={this.state.selectedValue === value.children[0].value ? "card unit" : "card"}
            onClick={() => this.selectInsurance(value)}
          >
            <div className="row no-gutters">
              <div className="col-2 col-md-1 p-0 btn-pink justify-content-center d-flex align-items-center rounded-left">
                <img
                  width="20"
                  className="checkIcon"
                  alt="Select Insurance"
                  src={this.state.selectedValue === value.children[0].value ? "/asset/check.png" : "/asset/plus.png"}
                />
              </div>
              <div className="col-10 col-md-11">
                <div className="card-body d-flex">
                  <p className="font-weight-bold  m-0 sixteen" style={{ fontSize: "20px" }}>
                    {value.children[1].value} Coverage
                  </p>
                  <p
                    className="ml-auto font-weight-bold m-0 text-darker sixteen"
                    rel={value.children[2].name}
                    style={{ fontSize: "20px" }}
                  >
                    ${Math.round(value.children[3].value)}/mo
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    };
    return (
      <div className="wrapper wrapper-flex py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <h1 className="font-weight-bold">Select {getInsuranceType()}</h1>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-8 mx-auto">
              {this.state.insurances.length === 0 && <BulletList />}
              <div className="row">{insuranceList()}</div>
              <div className="text-right">
                <button className="btn btn-link" onClick={this.handleHoi}>
                  I have my Own Coverage?
                </button>
              </div>
              <div className="row mt-4 justify-content-center">
                <div className="col-4">
                  {/* <button type="button" className="btn btn-light btn-lg letter-spacing-2" onClick={this.skip}>Skip</button> */}
                  {this.state.selectedValue !== 0 && (
                    <button
                      type="button"
                      className="btn btn-pink btn-lg letter-spacing-2 btn-block"
                      onClick={this.handleNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackButton backPath="/vehicle" />
      </div>
    );
  }
}

export default Insurance;
