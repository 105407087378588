import React from "react";
import axios from "axios";
import moment from "moment";
import XMLParser from "react-xml-parser";

import {
  amountDue,
  insuranceId,
  reservationObject,
  reservedUnitObject,
  locationCode,
  cardType,
  getPostResult,
  autopayBit,
  getInsuranceStatus,
  getGateCode,
  finishNewTenantNote,
  finalDiscountId,
  get24HourFee,
} from "../../../Utils/Common";
import { logApiFail } from "../../../Utils/rollbar";
import { confirmAlertShow, isValidPayment, makePayment } from "../../../Utils/paygateway";
import ProcessPaymentRetry from "../../../Utils/ProcessPaymentRetry";
import ConfirmPaymentPlaceholder from "../../../shared/ConfirmPaymentPlaceholder";
import { addGateAccessTimeZone, addTenantNotes } from "../../../Utils/data";

class ConfirmPayment extends ProcessPaymentRetry {
  componentDidMount() {
    const result = getPostResult();

    if (!reservationObject()) {
      confirmAlertShow("", "Invalid session!");
      window.location.href = "/";
      return;
    }
    const isValid = isValidPayment(
      result,
      () => {
        this.props.history.push("/lease-payment?retry=true");
      },
      () => {
        this.props.history.push("/lease-payment?retry=true");
      }
    );

    if (!isValid) {
      sessionStorage.setItem("payment_failure_notes", result.responseDescription);
      return;
    }

    const requestBody = {
      location_code: locationCode(),
      orderId: result.orderId,
    };

    makePayment(
      requestBody,
      (err) => {
        window.location.href = "/lease-payment?retry=true";
      },
      (responseData) => {
        this.finishRental(responseData);
      }
    );
  }

  finishRental = (transactionData) => {
    const reservationDetails = reservationObject();
    const unitData = reservedUnitObject();
    const feeFor24Hours = get24HourFee();

    const startDate = moment().format("L");
    const endDate = moment().add(29, "days").format("L");

    const requestBody = {
      location_code: locationCode(),
      tenant_id: reservationDetails.tenantId,
      gate_code: getGateCode(),
      unit_id: unitData.uId,
      insurance_id: insuranceId(),
      insurance_status_id: getInsuranceStatus(),
      discount_id: finalDiscountId(),
      start_date: startDate,
      end_date: endDate,
      amount: parseFloat(amountDue()).toFixed(2),
      payment_method: "CC",
      cc_type: cardType(transactionData.CARDBRAND[0]),
      cc_no: transactionData.MASKEDCARDNUMBER[0],
      cc_expiration_date: `${transactionData.EXPMONTH[0]}/${2000 + transactionData.EXPYEAR[0] * 1}`,
      cc_address: transactionData.ADDRESS1[0].slice(0, 50),
      cc_postal_code: transactionData.BILLINGPOSTALCODE[0],
      cc_name: transactionData.CUSTOMERNAME[0],
      transaction_id: transactionData.TRANSACTIONID[0],
      cc_response_code: transactionData.RESPONSECODE[0],
      res_code: reservationDetails.reservationCode,
      dbl_reservation_paid_fee: "0.00",
      autopay_bit: autopayBit(),
      order_id: transactionData.ORDERID[0],
      token: transactionData.ALIAS[0],
      doc_ref_number: "",
      doc_base64_format: localStorage.getItem("signed_lease_pdf") || "",
    };

    const has24HourFee = feeFor24Hours && feeFor24Hours.id && feeFor24Hours.amount > 0;

    if (has24HourFee) {
      requestBody.fee_id = feeFor24Hours.id
      requestBody.fee_amount = feeFor24Hours.amount
    }

    axios
      .post("/api/ssm/ConvertReservationtoRentalAndSendLeaseAgreement", JSON.stringify(requestBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        let data = new XMLParser().parseFromString(response.data);

        const messageKey = has24HourFee ? "ConvertReservationtoRentalAndSendLeaseAgreement_V2Message" : "ConvertReservationToMoveInMessage"
        const resultMsg = data.getElementsByTagName(messageKey)[0].value;

        if (resultMsg === "Successful") {
          const leaseNumberKey = has24HourFee ? "ConvertReservationtoRentalAndSendLeaseAgreement_V2LeaseNumber" : "ConvertReservationToMoveInLeaseNumber"
          const leaseNumber = data.getElementsByTagName(leaseNumberKey)[0].value;
          sessionStorage.setItem("created_lease_number", leaseNumber);

          if (sessionStorage.getItem("payment_failure_notes")) {
            addTenantNotes(
              locationCode(),
              reservationDetails.tenantId,
              "Kiosk Payment Failure:" + sessionStorage.getItem("payment_failure_notes")
            );
          }

          if (has24HourFee) {
            addGateAccessTimeZone(locationCode(), leaseNumber);
          }
          finishNewTenantNote(reservationObject().tenantId, "Credit Card");
          this.props.history.push("/movein-done");
        } else {
          throw data;
        }
      })
      .catch((e) => {
        if (this.retryCount < 3) {
          // Retry ConvertReservationtoRentalAndSendLeaseAgreement after 3 seconds. Retry count limit: 3.
          setTimeout(() => {
            this.finishRental(transactionData);
            this.retryCount += 1;
          }, 3000);
        } else {
          logApiFail("ConvertReservationtoRentalAndSendLeaseAgreement", requestBody, e);
        }
      });
  };

  render() {
    return <ConfirmPaymentPlaceholder />;
  }
}

export default ConfirmPayment;
