/* eslint-disable no-undef */
import React, { Component, Fragment } from "react";
import axios from "axios";
import { List } from "react-content-loader";
import {
  locationCode,
  moveInCostObject,
  unitObject,
  contactObject,
  amountDue,
  getInsuranceType,
  formatCurrency,
  get24HourFee,
} from "../../../Utils/Common";
import { logApiFail } from "../../../Utils/rollbar";
import withRetryPaymentCount from "../../HOC/withRetryPaymentCount";
class ProcessPaymentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      loading: true,
    };
  }

  componentDidMount() {
    const totalAmount = amountDue();
    const contactInfo = contactObject();

    const data = {
      location_code: locationCode(),
      total_amount: parseFloat(totalAmount).toFixed(2),
      rent_amount: parseFloat(totalAmount).toFixed(2),
      return_url: window.location.origin + "/confirm-payment",
      contact: contactInfo,
    };
    axios
      .post("/api/paygateway/hostpay/transactions", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        this.setState({
          url: response.data.paypageurl,
          loading: false,
        });
      })
      .catch((e) => {
        logApiFail("/paygateway/hostpay/transactions", data, e);
      });

    if (typeof KioApp !== "undefined") {
      KioApp.StartKeyboard("", "", "number", false, "", "");
    }

    const listener = window.addEventListener("blur", () => {
      if (document.activeElement === document.querySelector("iframe")) {
        if (typeof KioApp !== "undefined") {
          KioApp.StartKeyboard("", "", "number", false, "", "");
        }
      }
      window.removeEventListener("blur", listener);
    });
  }

  componentWillUnmount() {
    if (typeof KioApp !== "undefined") {
      KioApp.CloseKeyboard();
    }
  }

  render() {
    const moveInCost = moveInCostObject();
    const unit = unitObject();
    const feeFor24Hours = get24HourFee()

    return (
      <Fragment>
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                {this.state.loading ? (
                  <List backgroundColor={"#ccc"} />
                ) : (
                  <div>
                    <iframe
                      title="Paygateway Form"
                      className="paygateway-form"
                      src={this.state.url}
                      width="100%"
                    ></iframe>
                    <div className="credit-number-mask" />
                  </div>
                )}
              </div>
              <div className="col-sm-12 col-md-4">
                <h5 className="text-grey">Unit Information</h5>
                <div className="p-3 border rounded">
                  <p>
                    Your monthly rate for this unit is{" "}
                    {formatCurrency(moveInCost.RentDetails.TotalRentAmount)}
                  </p>
                  {moveInCost.InsuranceDetails.InsuranceAmount <=
                  0 ? null : (
                    <p>
                      <b>{getInsuranceType()} Amount:</b>{" "}
                      {formatCurrency(
                        moveInCost.InsuranceDetails.InsuranceAmount
                      )}
                    </p>
                  )}
                  {moveInCost.FeeDetails.FeesAmount <= 0 ? null : (
                    <p>
                      <b>Fee Amount:</b>{" "}
                      {formatCurrency(moveInCost.FeeDetails.FeesAmount)}
                    </p>
                  )}
                  {feeFor24Hours && (
                    <p>
                      <b>24-Hour Upgrade:</b> {formatCurrency(feeFor24Hours.amount)}
                    </p>
                  )}
                  {moveInCost.RentDetails.TaxOnRent <= 0 ? null : (
                    <p>
                      <b>Tax Amount:</b>{" "}
                      {formatCurrency(moveInCost.RentDetails.TaxOnRent)}
                    </p>
                  )}
                  {moveInCost.DiscountDetails.DiscountValue <= 0 ? null : (
                    <p>
                      <b>Discount: </b>
                      {formatCurrency(moveInCost.DiscountDetails.DiscountValue)}
                    </p>
                  )}
                  <p>
                    <b>Unit Type:</b> {unit.length} x {unit.breadth} {unit.sizeString}{" "}
                    {unit.unitTypeDetailedDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const PAGE_ID = "new_tenant_checkout_process_payment_card_retry_count";
const PAYMENT_METHOD_RETURN_URL = "/lease-payment";
export default withRetryPaymentCount(ProcessPaymentCard, PAGE_ID, PAYMENT_METHOD_RETURN_URL);
