import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { BulletList, List as ListLoader } from "react-content-loader";
import _ from "underscore";
import { confirmAlert } from "react-confirm-alert";
import {
  amountDue,
  unitObject,
  locationObject,
  contactObject,
  reservationObject,
  insuranceCoverage,
  taxSum,
  locationCode,
  setMoveInCostDataObject,
  reservedUnitObject,
  insuranceId,
  setAutopayBit,
  getGateCode,
  setGateCode,
  generateGateCode,
  getXmlAttribute,
  getInsuranceType,
  setLeasePdfSignedData,
  getPrevPageUrl,
  formatCurrency,
  get24HourFee,
} from "../../../Utils/Common";
import BackButton from "../../../shared/BackButton";
import { getTenantUnitList_V1 } from "../../../Utils/data";
import { formatMoveInCost } from "../../../shared/Unit";
import { logApiFail } from "../../../Utils/rollbar";

Modal.setAppElement("#root");

class LeaseSummary extends Component {
  constructor(props) {
    super(props);
    this.dataMissing = !reservationObject() || !unitObject() || !reservedUnitObject() || !locationObject();

    if (this.dataMissing) {
      this.props.history.push("/home");
    }

    const mylocation = locationObject();
    const unitInfo = unitObject();

    this.state = {
      reservationDetails: reservationObject(),
      unitData: reservedUnitObject(),
      contact: contactObject(),
      feeFor24Hours: get24HourFee(),
      moveInCost: false,
      showPdfModal: false,
      showSignModal: false,
      pdfUrl: false,
      leaseReady: false,
      submitting: false,
      autopay: mylocation.autopay_on_move_in,
      firstMonthFree: unitInfo.discountName === "First Month Free!" || unitInfo.discountAmount === "100.00",
    };
  }

  componentDidMount() {
    if (this.dataMissing) {
      return;
    }

    this.getExistingGateCodeForLocation();
    this.getMoveInCostAll();
  }

  getExistingGateCodeForLocation = async () => {
    const tenantId = reservationObject().tenantId;
    const leases = await getTenantUnitList_V1(locationCode(), tenantId);

    if (leases.length > 0) {
      const lease = leases[0];
      const data = {
        location_code: locationCode(),
        tenant_id: tenantId,
        lease_no: lease[0].LeaseNumber[0],
      };
      await axios
        .post("/api/ssm/GetTenantUnitLeaseDetails", JSON.stringify(data), {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (response) => {
          const data = response.data.TenantUnitDetails;
          const gateCode = getXmlAttribute(data, "GateCode");
          setGateCode(!_.isEmpty(gateCode) ? gateCode : generateGateCode());
        });
    } else {
      setGateCode(generateGateCode());
    }
  };

  showLeasePDF = () => {
    this.setState({ showPdfModal: true });
  };

  showLeaseFailure = () => {
    const mylocation = locationObject();
    const accessible = sessionStorage.getItem("accessible") === "on";
    const message = `Please call ${mylocation.phone} to complete your rental. We apologize for the inconvenience.`;

    confirmAlert({
      title: "",
      message: message,
      overlayClassName: accessible ? "accessible" : "",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            this.props.history.push("/home");
          },
        },
      ],
    });
  };

  getMoveInCostAll = () => {
    const unitInfo = unitObject();
    const discountId = this.state.firstMonthFree && !this.state.autopay ? "0" : unitInfo.discountId;

    const data = {
      service_name: "GetMoveInCostAll",
      xml_body: {
        strLocationCode: locationCode(),
        UnitId: this.state.unitData.uId,
        MoveInDate: moment().format("L"), // current date
        DiscountId: discountId,
        InsuranceId: insuranceId(),
      },
      optimized: true,
    };
    axios
      .post("/api/ssm", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setMoveInCostDataObject(response.data.Record);

        this.setState({ moveInCost: response.data.Record }, () => {
          this.generateLeasePDF(false);
        });
      });
  };

  generateLeasePDF = (signed = false, signatureData = null) => {
    const { contact, moveInCost, reservationDetails } = this.state;
    const unit = unitObject();
    const mylocation = locationObject();

    const timestamp = moment().format("L HH:mm:ss");
    const dueDate = moveInCost.RentDetails.PeriodWiseRentAmount0.ToDate;
    const nextBillingDate = moment(new Date(dueDate)).add(1, "day").format("L");

    const data = {
      name: contact.first + " " + contact.last,
      address: contact.address,
      cell_phone: contact.cell_phone,
      city: contact.city,
      state: contact.state,
      zip: contact.zip,
      email: contact.email,
      company: contact.company,
      move_in_date: moveInCost.RentDetails.PeriodWiseRentAmount0.FromDate,
      unit_number: reservationDetails.unitNumber,
      length: unit.length,
      breadth: unit.breadth,
      gate_code: getGateCode(),
      monthly_rent: moveInCost.RentDetails.TotalRentAmount,
      admin_fee: moveInCost.FeeDetails.FeesAmount,
      coverage: insuranceCoverage().toString(),
      insurance_amount: moveInCost.InsuranceDetails.InsuranceAmount,
      tax: taxSum().toFixed(2),
      discount: moveInCost.DiscountDetails.DiscountValue,
      total_rent: amountDue().toString(),
      due_on: dueDate,
      next_billing_date: nextBillingDate,
      facility_name: mylocation.name,
      facility_address: mylocation.address,
      facility_phone: mylocation.phone,
      facility_email: mylocation.reservation_email,
      facility_city: mylocation.city,
      facility_zip: mylocation.zip,
      facility_state: mylocation.state,
      current_date: timestamp,
      accepted_timestamp: signed ? `Accepted by Customer ${contact.first} ${contact.last} at ${timestamp}` : "",
      signature_data: signatureData,
      format: "file",
      location_code: locationCode(),
    };

    axios
      .post("/api/ssm/lease-pdf", data, {
        headers: { "Content-Type": "application/json" },
        responseType: "blob",
      })
      .then((response) => {
        setLeasePdfSignedData(data);

        var file = new Blob([response.data], {
          type: "application/pdf;base64",
        });

        if (signed) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            var base64data = reader.result;
            localStorage.setItem("signed_lease_pdf", base64data.substr(base64data.indexOf(",") + 1));
            this.props.history.push("/lease-payment");
          };
        } else {
          const blobUrl = URL.createObjectURL(file);
          this.setState({ leaseReady: true, pdfUrl: blobUrl + "#toolbar=0" });
        }
      })
      .catch((e) => {
        logApiFail("/api/ssm/lease-pdf", data, e);
        this.showLeaseFailure();
      });
  };

  handleSubmit = () => {
    const signatureData = this.signatureRef.getTrimmedCanvas().toDataURL("image/png");

    setAutopayBit(this.state.autopay ? "1" : "0");
    this.generateLeasePDF(true, signatureData);
    this.setState({ showSignModal: false, submitting: true });
  };

  handleAutopay = (e) => {
    this.setState({ autopay: e.target.checked }, () => {
      if (this.state.firstMonthFree) {
        this.getMoveInCostAll();
      }
    });
  };

  render() {
    const { moveInCost } = this.state;
    const accessible = sessionStorage.getItem("accessible") === "on";

    const unit = unitObject();
    return (
      <div className="wrapper wrapper-flex">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <h1 className="font-weight-bold">Lease Summary </h1>
              </div>
            </div>
          </div>
          {!moveInCost ? (
            <BulletList />
          ) : (
            <div className="row mt-5">
              <div className="col-sm-12 col-md-8 mx-auto">
                <div className="card shadow border-0">
                  <div className="card-header bg-white rounded">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="m-0" style={{ fontSize: "22px" }}>
                          {`${unit.length}'x${unit.breadth}' | ${unit.categoryName}`}
                        </h5>
                      </div>
                      <div className="col-6 text-danger text-right">
                        <h5 className="m-0" style={{ fontSize: "22px" }}>
                          {formatMoveInCost(moveInCost)}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div>
                      <h5 className="text-black font-weight-bold">Summary of Terms</h5>
                      <ul className="text-black font-weight-bold">
                        <li>Month-to-month term, notice to vacate must be received before your next billing cycle</li>
                        <li>Rental rate and other lease terms subject to change upon notice</li>
                        <li>You need to lock and insure your property as Lessor is not liable</li>
                        <li>Failure to pay rent timely could result in the auction of your property</li>
                        <li>Please note if you are a member of the military</li>
                        <li>Your lease contains rules and regulations</li>
                        <li>Hours of operation are posted on website</li>
                        <li>Monthly invoice will be sent upon request</li>
                      </ul>
                      <p className="text-black font-weight-bold">
                        Please read your entire lease to know all of your terms. By clicking Accept below, you
                        acknowledge you have read, understand, and agree to the full terms of the lease.
                      </p>
                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-6 mb-4">
                        <h5 className="text-black">Rent Details</h5>
                        <p className="m-0">Total Rent: {formatCurrency(moveInCost.RentDetails.TotalRentAmount)}</p>
                        <p className="m-0">Tax On Rent: {formatCurrency(moveInCost.RentDetails.TaxOnRent)}</p>
                        {this.state.feeFor24Hours && (
                          <p className="m-0">24-Hour Upgrade: {formatCurrency(this.state.feeFor24Hours.amount)}</p>
                        )}
                      </div>
                      {moveInCost.InsuranceDetails.InsuranceAmount > 0 && (
                        <div className="col-sm-6 mb-4">
                          <h5 className="text-black">{getInsuranceType()} Details</h5>
                          <p className="m-0">Description: {formatCurrency(insuranceCoverage())} Coverage</p>
                          <p className="m-0">
                            {getInsuranceType()} Amount: {formatCurrency(moveInCost.InsuranceDetails.InsuranceAmount)}
                          </p>
                          <p className="m-0">
                            Tax On {getInsuranceType()}: {formatCurrency(moveInCost.InsuranceDetails.TaxOnInsurance)}
                          </p>
                        </div>
                      )}
                      {moveInCost.DiscountDetails.DiscountValue > 0 && (
                        <div className="col-sm-6 mb-4">
                          <h5 className="text-black">Discount Details</h5>
                          <p className="m-0">Description: {moveInCost.DiscountDetails.Description}</p>
                          <p className="m-0">Amount: {formatCurrency(moveInCost.DiscountDetails.DiscountValue)}</p>
                        </div>
                      )}
                      {moveInCost.FeeDetails.FeesAmount > 0 && (
                        <div className="col-sm-6 mb-4">
                          <h5 className="text-black">Fee Details</h5>
                          <p className="m-0">Description: {moveInCost.FeeDetails.Description}</p>
                          <p className="m-0">Amount: {formatCurrency(moveInCost.FeeDetails.FeesAmount)}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="form-group form-check mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="autopay"
                        id="autopay"
                        checked={this.state.autopay}
                        onChange={this.handleAutopay}
                      />
                      <label className="form-check-label ml-2" htmlFor="autopay">
                        Enable Automatic Monthly Payments
                        {this.state.firstMonthFree
                          ? " | Autopay is required for the First Month Free promotion. Removing autopay will remove your discount and refresh your lease agreement."
                          : ""}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-4">
                    {this.state.leaseReady ? (
                      <button className="btn btn-link p-0" onClick={this.showLeasePDF}>
                        See lease details
                      </button>
                    ) : (
                      <ListLoader backgroundColor="#bbb" viewBox="0 0 250 20" />
                    )}
                  </div>
                  <div className="col-4" />
                  <div className="col-4 text-right">
                    {this.state.submitting ? (
                      <h6 className="p-2 text-pink">Submitting lease document...</h6>
                    ) : (
                      <button
                        onClick={() => this.setState({ showSignModal: true })}
                        className="btn btn-pink btn-lg letter-spacing-2"
                      >
                        Accept
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal isOpen={this.state.showSignModal} style={modalStyles(accessible)}>
          <div className="modal-header">
            <h4>Draw your signature</h4>
            <div onClick={() => this.setState({ showSignModal: false })} className="modal-close-btn">
              <MdClose size="2em" />
            </div>
          </div>
          <div className="modal-content">
            <SignaturePad
              ref={(ref) => (this.signatureRef = ref)}
              canvasProps={{
                width: 600,
                height: 200,
                className: "sig-canvas",
              }}
            />
          </div>
          <div className="modal-footer">
            <button onClick={() => this.signatureRef.clear()} className="btn btn-secondary">
              Reset
            </button>
            <button onClick={this.handleSubmit} className="btn btn-pink">
              Submit
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showPdfModal}
          onRequestClose={() => this.setState({ showPdfModal: false })}
          style={modalStyles(false)}
        >
          <div onClick={() => this.setState({ showPdfModal: false })} className="modal-close-btn">
            <MdClose size="3em" />
          </div>
          <div className="modal-content pdf-modal-content" style={{ width: "calc(100vw - 64px)", height: "85vh" }}>
            {this.state.pdfUrl ? (
              <iframe title="Lease PDF" type="application/pdf" src={this.state.pdfUrl} width="100%" height="100%" />
            ) : (
              <BulletList />
            )}
          </div>
          <div className="modal-footer">
            <div onClick={() => this.setState({ showPdfModal: false })} className="btn btn-secondary float-right">
              Close
            </div>
          </div>
        </Modal>
        <BackButton backPath={getPrevPageUrl("leaseSummary", this.props)} />
      </div>
    );
  }
}

const modalStyles = (accessible) => ({
  content: {
    top: accessible ? "80%" : "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
});
export default LeaseSummary;
