import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { resetKeyboard, setInput } from "../../../../redux/Keyboard/keyboard.action";
import BackButton from "../../../shared/BackButton";
import {
  contactObject,
  fullDayAccessPath,
  getPrevPageUrl,
  reservationObject,
  setInsuranceId,
  setInsuranceStatus,
  setTenantNote,
} from "../../../Utils/Common";

function VehicleInfo(props) {
  const [pdfModal, setPdfModal] = useState(false);
  const dispatch = useDispatch();
  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  if (contactObject() === null || reservationObject() === null) {
    props.history.push("/");
  }

  useEffect(() => {
    dispatch(resetKeyboard(["make", "model", "color", "vehicle_description"]));
  }, [dispatch]);

  useEffect(() => {
    props.setFieldValue(currentInput, currentValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleFocus = (inputName) => {
    const value = props.values[inputName];
    dispatch(setInput(inputName, value || ""));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!props.isValid) {
      props.setTouched({ model: true, make: true, color: true, confirmValid: true });
      return;
    }

    const note = `Vehicle Description: Make: ${props.values.make}, Model: ${props.values.model}, Color: ${props.values.color}, Description: ${props.values.vehicle_description}`;
    setInsuranceId("0");
    setInsuranceStatus("3");
    setTenantNote(note);
    props.history.push(fullDayAccessPath(), { prevPageUrl: "/vehicle-info" });
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-8 mx-auto">
              <h1 className="font-weight-bold text-center mb-4">Vehicle Info</h1>
              <div className="text-center">
                <div className="form-group">
                  <input
                    className="form-control form-control-lg"
                    name="make"
                    placeholder="Make"
                    value={props.values.make}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    onFocus={() => handleFocus("make")}
                  />
                  {props.touched.make && props.errors.make && (
                    <span className="text-danger text-left d-block ml-1">{props.errors.make}</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className="form-control form-control-lg"
                    name="model"
                    placeholder="Model"
                    value={props.values.model}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    onFocus={() => handleFocus("model")}
                  />
                  {props.touched.model && props.errors.model && (
                    <span className="text-danger text-left d-block ml-1">{props.errors.model}</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className="form-control form-control-lg"
                    name="color"
                    placeholder="Color"
                    value={props.values.color}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    onFocus={() => handleFocus("color")}
                  />
                  {props.touched.color && props.errors.color && (
                    <span className="text-danger text-left d-block ml-1">{props.errors.color}</span>
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    rows="8"
                    className="form-control form-control-lg"
                    placeholder="Vehicle Description"
                    autoComplete="off"
                    name="vehicle_description"
                    value={props.values.vehicle_description}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    onFocus={() => handleFocus("vehicle_description")}
                  />
                </div>
                <div className="card text-left p-3 mb-4">
                  <p>
                    <b>Please Note:</b>
                  </p>
                  <ul>
                    <li>Vehicles must be in drivable and in working order unless vehicle is trailered.</li>
                    <li>
                      A designated space can only hold one vehicle, unless otherwise specified such as a truck and
                      trailer or truck and boat trailer combo. Combination of the two must not use more space than
                      allotted area permits.
                    </li>
                    <li>No vehicles that are unsightly, deteriorating, or in disrepair.</li>
                    <li>
                      Tires must be in reasonable, working order and must remain inflated. Any vehicle with flat tires
                      will be required to repair and/or re-inflate.
                    </li>
                    <li>
                      Please find full parking guidelines{" "}
                      <span className="link" onClick={() => setPdfModal(true)} href="#">
                        here
                      </span>
                      .
                    </li>
                  </ul>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="confirmValid"
                    name="confirmValid"
                    checked={props.values.confirmValid}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  <label className="form-check-label ml-2" htmlFor="confirmValid">
                    I confirm that the vehicle has valid and current insurance.
                  </label>
                  {props.touched.confirmValid && props.errors.confirmValid && (
                    <span className="text-danger text-left d-block ml-1 text-center">{props.errors.confirmValid}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                name="submit"
                type="submit"
                disabled={props.isSubmitting}
                className={`btn btn-pink btn-lg letter-spacing-2`}
              >
                Next
              </button>
            </div>
          </div>
        </form>
        <Modal isOpen={pdfModal} onRequestClose={() => setPdfModal(false)} style={modalStyles(false)}>
          <div onClick={() => setPdfModal(false)} className="modal-close-btn">
            <MdClose size="3em" />
          </div>
          <div className="modal-content pdf-modal-content" style={{ width: "calc(100vw - 64px)", height: "85vh" }}>
            <iframe
              title="Storage Guidelines for Vehicles"
              type="application/pdf"
              src="/img/Storage-Guidelines-for-Vehicles-01-20-23-revised.pdf"
              width="100%"
              height="100%"
            />
          </div>
          <div className="modal-footer">
            <div onClick={() => setPdfModal(false)} className="btn btn-secondary float-right">
              Close
            </div>
          </div>
        </Modal>
      </div>
      <BackButton backPath={getPrevPageUrl("vehicleInfo", props)} />
    </div>
  );
}

const modalStyles = (accessible) => ({
  content: {
    top: accessible ? "80%" : "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
});

VehicleInfo.propTypes = {};

export default withFormik({
  mapPropsToValues: () => {
    return {
      make: "",
      model: "",
      color: "",
      vehicle_description: "",
      confirmValid: false,
    };
  },
  validationSchema: Yup.object().shape({
    make: Yup.string().required("Make required"),
    model: Yup.string().required("Model required"),
    color: Yup.string().required("Color required"),
    vehicle_description: Yup.string(),
    confirmValid: Yup.boolean().oneOf([true], "You must confirm that the vehicle has valid and current insurance."),
  }),
})(VehicleInfo);
