import React from "react";
import BackButton from "../../../shared/BackButton";
import { getPrevPageUrl, locationCode, set24HourFee, unitObject } from "../../../Utils/Common";
import { getFeesAndChargesV1 } from "../../../Utils/data";

function FullDayAccess(props) {
  const handleOption = async (value) => {
    if (value === "yes") {
      const unitObj = unitObject()
      const feesData = await getFeesAndChargesV1(locationCode(), unitObj.unitTypeId);
      const feeFor24Hours = feesData.GetFeesandCharges_V1.find(e => e.FeeName[0] === "24 Hour Access Fee")
      const feeObj = {
        id: feeFor24Hours?.FeeId[0] || 0,
        amount: feeFor24Hours?.FeeAmount[0] || 0,
      }
      console.log(feeObj)
      set24HourFee(feeObj);
    } else {
      set24HourFee("");
    }
    props.history.push("/lease-summary", { prevPageUrl: "/full-day-access" });
  }

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="font-weight-bold text-center mb-4">Would You Like 24-Hour Access to Your Unit For Only $20 Per Month?</h1>
            <div className="form-group mt-5">
              <button
                onClick={(e) => handleOption("yes")}
                className="btn btn-lg btn-block btn-pink"
              >
                Yes
              </button>
            </div>
            <div className="form-group mt-5">
              <button
                onClick={(e) => handleOption("no")}
                className="btn btn-lg btn-block btn-pink"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <BackButton backPath={getPrevPageUrl("vehicle", props)} />
    </div>
  );
}

export default FullDayAccess;
