import React from "react";
import { useInitPaymentAchData } from "../../../../hooks";
import { processACHMoveIn } from "../../../services/payment";
import {
  amountDue,
  finishNewTenantNote,
  formatCurrency,
  get24HourFee,
  getHandlerFocus,
  getInsuranceType,
  moveInCostObject,
  reservationObject,
  unitObject,
} from "../../../Utils/Common";
import { handleCountRetryPaymentAch, isValidRoutingNumberAndAccountNumber } from "../../../Utils/paygateway";
import PaymentAchDetail from "../../../Utils/PaymentAchDetail";

function ProcessPaymentAch(props) {
  const [
    routingNumber,
    setRoutingNumber,
    accountNumber,
    setAccountNumber,
    message,
    setMessage,
    processing,
    setProcessing,
    dispatch,
    setInput,
  ] = useInitPaymentAchData();

  const moveInCost = moveInCostObject();
  const unit = unitObject();
  const totalAmount = amountDue();
  const feeFor24Hours = get24HourFee()

  const handleFocus = getHandlerFocus(dispatch, setInput);

  if (!moveInCost || !unit) {
    alert("Something went wrong!");
    props.history.push("/lease-payment");
    return false;
  }

  const submitAchPayment = () => {
    const reservationDetails = reservationObject();
    const isValid = isValidRoutingNumberAndAccountNumber(routingNumber, accountNumber, setMessage);
    if (!isValid) return;

    setProcessing(true);

    processACHMoveIn({ routingNumber, accountNumber }).then((result) => {
      if (result && result.Rental && result.Rental.RentalMessage[0] === "Successful") {
        finishNewTenantNote(reservationDetails.tenantId, "ACH");

        const leaseNumber = result.Rental.RentalLeaseNumber[0];
        sessionStorage.setItem("created_lease_number", leaseNumber);
        props.history.push("/movein-done");
      } else {
        handleCountRetryPaymentAch(
          "new_tenant_checkout_process_payment_ach_retry_count",
          props,
          () => {
            // if exceed max retry count
            setProcessing(false);
            props.history.push("/lease-payment");
          },
          () => {
            // if NOT exceed max retry count
            setProcessing(false);
            setMessage("Failed to process ACH payment. Please contact customer support.");
          }
        );
      }
    });
  };

  return (
    <div className="wrapper">
      <div className="container">
        <div className="row py-5">
          <PaymentAchDetail
            containerClassName="col-sm-12 offset-md-1 col-md-6"
            accountNumber={accountNumber}
            handleFocus={handleFocus}
            message={message}
            processing={processing}
            routingNumber={routingNumber}
            setAccountNumber={setAccountNumber}
            setRoutingNumber={setRoutingNumber}
            submitAchPayment={submitAchPayment}
            totalAmount={totalAmount}
          />
          <div className="col-sm-12 offset-md-1 col-md-4">
            <h5 className="text-grey">Unit Information</h5>
            <div className="p-3 border rounded">
              <p>
                Your monthly rate for this unit is{" "}
                {formatCurrency(moveInCost.RentDetails.TotalRentAmount)}
              </p>
              {moveInCost.InsuranceDetails.InsuranceAmount <= 0 ? null : (
                <p>
                  <b>{getInsuranceType()} Amount:</b>{" "}
                  {formatCurrency(
                    moveInCost.InsuranceDetails.InsuranceAmount
                  )}
                </p>
              )}
              {moveInCost.FeeDetails.FeesAmount <= 0 ? null : (
                <p>
                  <b>Fee Amount:</b>{" "}
                  {formatCurrency(moveInCost.FeeDetails.FeesAmount)}
                </p>
              )}
              {feeFor24Hours && (
                <p>
                  <b>24-Hour Upgrade:</b> {formatCurrency(feeFor24Hours.amount)}
                </p>
              )}
              {moveInCost.RentDetails.TaxOnRent <= 0 ? null : (
                <p>
                  <b>Tax Amount:</b>{" "}
                  {formatCurrency(moveInCost.RentDetails.TaxOnRent)}
                </p>
              )}
              {moveInCost.DiscountDetails.DiscountValue <= 0 ? null : (
                <p>
                  <b>Discount: </b>
                  {formatCurrency(moveInCost.DiscountDetails.DiscountValue)}
                </p>
              )}
              <p>
                <b>Unit Type:</b> {unit.length} x {unit.breadth} {unit.sizeString} {unit.unitTypeDetailedDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessPaymentAch;
